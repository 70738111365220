import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/privacyofpolicy',
    name: 'privacyofpolicy',
    component: () => import('@/views/privacyofpolicy')
  },
  {
    path: '/termsofuse',
    name: 'termsofuse',
    component: () => import('@/views/termsofuse')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/support')
  },
  {
    path: '/SteamDistributionAgreement',
    name: 'SteamDistributionAgreement',
    component: () => import('@/views/SteamDistributionAgreement')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
